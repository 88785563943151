import api from "@/api";

export default {
  state: {
      teamTaxes: []
  },
  getters: {
      teamTaxes: (state) => state.teamTaxes,

  },
  mutations: {
    setTeamTaxes: (state, data) => {
      state.teamTaxes = data;
    },

  },
  actions: {
    getTeamTaxes: async ({ commit }, id) => {
      try {
        const response = await api({ requiresAuth: true }).get(`/v1/team-taxes/${id}`);
        const { data } = response.data;
        commit("setTeamTaxes", data);
      } catch (err) {
        console.error(err);
      }
    },
    updateTax: (
        { commit, dispatch},
        {
          id: id,
          rate

        }
    ) =>
        new Promise((resolve, reject) => {
          api({ requiresAuth: true })
              .patch("/v1/taxes/" + id, {
                rate: rate,
              })
              .then(
                  async (response) => {
                      const { data } = response.data;
                      commit("setTeamsLoading", true);
                      await dispatch("getTeamTaxes", data.team.id);
                      resolve(data);
                      commit("setTeamsLoading", false);
                  },
                  (err) => {
                    commit("setTeamTaxesLoading", false);
                    reject(err);
                  }
              );
        }),

  },


};
