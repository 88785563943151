import api from "@/api";

export default {
  state: {
    userForms: {},
    customerForms: {},
    policyForms: {},
    terminationForms: {},
    companyForms: {},
    potentialPolicyForms: {},
    reportForms: {},
    teamForms: {},
    teamTaxesForms: {},
  },
  getters: {
    userForms: (state) => state.userForms,
    customerForms: (state) => state.customerForms,
    policyForms: (state) => state.policyForms,
    terminationForms: (state) => state.terminationForms,
    companyForms: (state) => state.companyForms,
    potentialPolicyForms: (state) => state.potentialPolicyForms,
    reportForms: (state) => state.reportForms,
    teamForms: (state) => state.teamForms,
    teamTaxesForms: (state) => state.teamForms,
  },
  mutations: {
    setUserForms: (state, data) => {
      state.userForms = data;
    },
    setTeamForms: (state, data) => {
      state.teamForms = data;
    },
    setCustomerForms: (state, data) => {
      state.customerForms = data;
    },
    setPolicyForms: (state, data) => {
      state.policyForms = data;
    },
    setTerminationForms: (state, data) => {
      state.terminationForms = data;
    },
    setCompanyForms: (state, data) => {
      state.companyForms = data;
    },
    setPotentialPolicyForms: (state, data) => {
      state.potentialPolicyForms = data;
    },
    setReportForms: (state, data) => {
      state.reportForms = data;
    },
    setTeamTaxesForms: (state, data) => {
      state.reportForms = data;
    },
  },
  actions: {
    getUserForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/user-forms"
        );
        const { data } = response.data;
        commit("setUserForms", data);
      } catch (err) {
        console.error(err);
      }
    },
    getTeamForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
            "/v1/partials/team-forms"
        );
        const { data } = response.data;
        commit("setTeamForms", data);
      } catch (err) {
        console.error(err);
      }
    },
    getTeamTaxesForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
            "/v1/partials/team-tax-forms"
        );
        const { data } = response.data;
        commit("setTeamTaxesForms", data);
      } catch (err) {
        console.error(err);
      }
    },
    getCustomerForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/customer-forms"
        );
        const { data } = response.data;
        commit("setCustomerForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getPolicyForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/policy-forms"
        );
        const { data } = response.data;
        commit("setPolicyForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getCompanyForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/company-forms"
        );
        const { data } = response.data;
        commit("setCompanyForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getPotentialPolicyForms: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/partials/potential-policy-forms"
        );
        const { data } = response.data;
        commit("setPotentialPolicyForms", data);
      } catch (err) {
        console.error(err);
      }
    },

    getReportForms: async ({ commit }) => {
      try {
        const {
          data: { data },
        } = await api({ requiresAuth: true }).get("/v1/partials/report-forms");
        commit("setReportForms", data);
      } catch (err) {
        console.error(err);
      }
    },

  },
};
