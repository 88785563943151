import Vuex from "vuex";
import Vue from "vue";
import application from "./modules/application";
import authorization from "./modules/authorization";
import roles from "./modules/roles";
import permissions from "./modules/permissions";
import users from "./modules/users";
import teams from "./modules/teams";
import teamTaxes from "@/store/modules/teamTaxes";
import genders from "./modules/genders";
import titles from "./modules/titles";
import languages from "./modules/languages";
import countries from "./modules/countries";
import salesCategories from "./modules/salesCategories";
import zipCodes from "./modules/zipCodes";
import companies from "./modules/companies";
import customers from "./modules/customers";
import companyTpes from "./modules/companyTypes";
import banks from "./modules/banks";
import policyStatuses from "./modules/reference-tables/policyStatuses";
import services from "./modules/services";
import intervals from "./modules/reference-tables/intervals";
import franchises from "./modules/reference-tables/franchises";
import accidents from "./modules/reference-tables/accidents";
import models from "./modules/models";
import partials from "./modules/partials";
import policies from "./modules/policies";
import policyServices from "./modules/policyServices";
import terminations from "./modules/terminations";
import modelCategories from "./modules/modelCategories";
import media from "./modules/media";
import saleCategoryPrices from "./modules/saleCategoryPrices";
import saleCategoryPriceCompanies from "./modules/saleCategoryPriceCompanies";
import potentialPolicy from "./modules/potentialPolicy";
import searches from "./modules/searches";
import policyServicePayments from "./modules/policySevicePayments";
import policyInvoices from "./modules/policyInvoices";
import branchCategoryServices from "./modules/branchCategoryServices";
import transactions from "./modules/transactions";
import reports from "./modules/reports";
import settings from "./modules/settings";  

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    authorization,
    roles,
    permissions,
    users,
    teams,
    teamTaxes,
    genders,
    titles,
    languages,
    countries,
    salesCategories,
    zipCodes,
    companies,
    customers,
    companyTpes,
    banks,
    policyStatuses,
    services,
    intervals,
    franchises,
    accidents,
    models,
    partials,
    policies,
    policyServices,
    terminations,
    modelCategories,
    media,
    saleCategoryPrices,
    saleCategoryPriceCompanies,
    potentialPolicy,
    searches,
    policyServicePayments,
    policyInvoices,
    branchCategoryServices,
    transactions,
    reports,
    settings,
  },
});
