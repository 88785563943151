<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--team"></div>

      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">


        <form class="add__form">
          <transition name="fade" mode="out-in">
            <Loading v-if="formLoading"/>
            <div v-else class="add__content">
              <transition name="fade" mode="out-in">
                <div class="details" key="0">


                  <div class="form__field">
                    <input
                        type="text"
                        class="input input--default"
                        placeholder="Team name *"
                        disabled
                        v-model="this.team.name"

                    />



                  </div>
                  <div class="form__field">
                    <input
                        type="text"
                        class="input input--default"
                        placeholder="Tax name *"
                        disabled
                        v-model="name"
                        :class="{
                        'input--error': nameHasError,
                      }"
                    />
                    <div class="form__error" v-if="nameHasError">
                      {{ nameErrorPlaceholder }}
                    </div>


                  </div>
                  <div class="form__field">
                    <input
                        type="text"
                        class="input input--default"
                        placeholder="Tax Group *"
                        disabled
                        v-model="groupName"

                    />



                  </div>
                  <div class="form__field">

                      <div class="form__rate">
                        <div class="form__input--rate">
                          <input
                              type="text"
                              class="input input--default input--rate"
                              placeholder="Rate *"

                              v-model="rateReadable"

                          />
                        </div>
                        <div class="form__label rate__label">%</div>
                      </div>



                  </div>



                </div>

              </transition>

            </div>
          </transition>

        </form>
      </div>
      <div
          v-if="isRequesting"
          class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
          v-else
          type="button"
          class="modal__button modal__button--submit button button--default"
          @click="createTeamTax()"
      >
        <span>Save</span>

      </button>
    </div>
  </div>
</template>

<script>

import Loading from "@/partials/Loading";
import statics from "@/statics";
import parseFieldErrors from "@/helpers/parseFieldErrors";
import {mapGetters} from "vuex";

export default {
  name: "AddTeamTaxModal",

  components: {
    Loading,
  },

  data() {
    return {
      statics,
      parseFieldErrors,
      id: "",
      name: "",
      groupName: "",
      rate: "",
      rateReadable: "",
      team: [],
      nameHasError: false,
      formLoading: false,
      isRequesting: false,
      specificErrors: [],
    };
  },


  async mounted() {
    this.formLoading = true;
    await this.$store.dispatch("getTeamTaxesForms");
  //  this.loadFieldsForEdit(this.tax);
    if (this.teamTaxIsEdit) {
      this.name = this.teamTaxToEdit.name || "";
      this.team = this.teamTaxToEdit.team;
      this.groupName = this.teamTaxToEdit.group_name;
      this.rateReadable = this.teamTaxToEdit.rate * 100;
      this.rate = this.teamTaxToEdit.rate;
      this.id = this.teamTaxToEdit.id;
      this.team = this.teamTaxToEdit.team;
    }

    this.formLoading = false;
  },

  computed: {
    ...mapGetters(["teamTaxesForms", "teamTaxIsEdit", "teamTaxToEdit", "teamTaxesLoading"]),
  },
  watch: {
    rateReadable(){
      this.rate = this.rateReadable / 100;
    }
  },
  methods: {
    closeModal() {
      this.$store.commit("setShowAddTeamTaxModal", false);
      this.$store.commit("setTeamTaxIsEdit", false);
      this.$store.commit("setTeamTaxToEdit", null);

    },
    validateForm() {
      if (!this.name) {
        this.nameHasError = true;
      }

    },

    async createTeamTax() {
      this.validateForm();
      if (!this.nameHasError) {
        this.isRequesting = true;
        try {

          if (this.teamTaxIsEdit) {

            await this.$store.dispatch("updateTax", {
              id: this.id,
              rate: this.rate,

            });
            this.$store.commit("setToast", {
              display: true,
              type: "success",
              message: "Team tax updated successfully.",
            });

            this.closeModal();
           /* this.$store.commit("setTeamTaxesLoading", true);
            await  this.$store.dispatch("getTeamTaxes", this.teamId);

            this.$store.commit("setTeamTaxesLoading", false);*/
            this.isRequesting = false;
          }

        } catch (err) {
          console.log(err);

         this.isRequesting = false;
        }
      }
    },

    uploadImage(event) {
      this.file = event.target.files[0];
      this.userImage = URL.createObjectURL(this.file);
    },
  },

};
</script>
<style scoped lang="scss">
   .form__rate{
     display: flex;
   }
   .rate__label {
     width: 25px;
     color: var(--text-2);
     font-size: var(--x-smaller);
     padding-left: 5px;
     margin-top: 14px;
   }
  .form__input--rate {
    width: calc(100% - 25px) !important;


  }
</style>