<template>
  <div class="team row row--hover box box--default">
    <div
        class="row__data row__data--small row__data--image team__logo"

    >
      <img v-if="userThumbnail" :src="userThumbnail" alt="" />
      <div v-else class="thumbnail thumbnail--team" :style="color">
        {{ parseInitials(tax.team.name) }}
      </div>
    </div>
    <div
      class="row__data row__data--large row__data--name"
     
    >
      {{ tax.team.name }}
    </div>


    <div
      class="row__data row__data--large row__data--address"
     
    >
      {{ tax.group_name }}
    </div>
    <div
      class="row__data row__data--large row__data--address"

    >
      {{ tax.name }}
    </div>
    <div
      class="row__data row__data--medium row__data--rate"

    >
      {{ rateReadable }}
    </div>

    <div
      class="row__data row__data--action row__data--small"
      @click.self="showActionsDropdown = !showActionsDropdown"
      v-if="$checkRolePermission('tax_edit')"
    >
      <RowActionDropdown
        v-if="showActionsDropdown"
        @toggleDropdown="showActionsDropdown = $event"
        :onEdit="editTax"
        :onDelete="deleteTax"


      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";


import { parseInitials } from "@/helpers/entityParsers";
import RowActionDropdown from "@/components/RowActionDropdown";
export default
{
  name: "TeamTax",

  components: {
   RowActionDropdown,

  },

  props: {
    tax: {
      required: true,
      type: Object,
    },


  },

  data() {
    return {
      showActionsDropdown: false,
      parseInitials,
    };
  },

  computed: {
    ...mapGetters(["users", "saleCategories"]),

    userThumbnail() {
      return this.tax.team.photo ? this.tax.team.photo.thumbnail : null;
    },

    color() {
      return this.tax.team.color
          ? { background: this.tax.team.color }
          : { background: "#7f8fa4" };
    },

    rateReadable() {
        return (this.tax.rate * 100) + " %";
    }
  },

  methods: {


    editTax() {
      this.$store.commit("setTeamTaxToEdit", this.tax);
      this.$store.commit("setTeamTaxIsEdit", true);
      this.$store.commit("setShowAddTeamTaxModal", true);
    },
    deleteTax() {

    },







  },
};
</script>
<style scoped lang="scss">
.row__data--action {
  max-width: 70px;
}

.team__logo{
  max-width: 70px;
}
</style>