import api from "@/api";
import router from "@/router";

export default {
  state: {
    users: [],
    user: {},
    agents: [],
    usersPerPage: {},
    currentUsersPage: 1,
    notifications: [],

  },

  getters: {
    users: (state) => state.users,
    user: (state) => state.user,
    usersPerPage: (state) => state.usersPerPage,
    currentUsersPage: (state) => state.currentUsersPage,
    agents: (state) => state.agents,
    notifications: (state) => state.notifications,

  },

  mutations: {
    setUsers: (state, data) => {
      state.users = data;
    },
    setUser: (state, data) => {
      state.user = data;
    },
    setUsersPerPage: (state, data) => {
      state.usersPerPage = data;
    },
    setCurrentUsersPage: (state, data) => {
      state.currentUsersPage = data;
    },
    setAgents: (state, data) => {
      state.agents = data;
    },
    setNotifications: (state, data) => {
      state.notifications = data;
    },

  },

  actions: {
    getUsers: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get("/v1/users");
        const { data } = response.data;
        commit("setUsers", data);
      } catch (err) {
        console.log(err);
      }
    },

    deleteUser: async ({ commit, dispatch, getters }, userId) => {
      commit("setUsersLoading", true);
      try {
        await api({ requiresAuth: true }).delete(`/v1/users/${userId}`);
        await dispatch("getUsers");
        await dispatch("getUsersPage", getters.currentUsersPage);
      } catch (err) {
        console.error(err);
      }
      commit("setUsersLoading", false);
    },

    createUser: (
      { commit, dispatch, getters },
      {
        firstName,
        lastName,
        email,
        password,
        titleId,
        companyName,
        gender,
        countryCode,
        birthday,
        password_confirmation,
        //prefix,
        phone,
        ahvNr,
        roles,
        user_addresses,
        user_banks,
        settings,
        teamId,
        supervisorId,
        salesCategoryId,
        language,
        file,
        hasAhv,
        hasBvg,
        bvgValue
      }
    ) =>
      new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .post("/v1/users", {
            first_name: firstName,
            last_name: lastName,
            email,
            password,
            title_id: titleId,
            company_name: companyName,
            gender_id: gender,
            country_code: countryCode,
            birthday,
            phone,
         //   prefix,
            ahv_number: ahvNr,
            roles,
            user_addresses,
            user_banks,
            password_confirmation,
            settings,
            team_id: teamId,
            supervisor_id: supervisorId,
            language_id: language,
            sales_category_id: salesCategoryId,
              has_ahv: hasAhv,
              has_bvg: hasBvg,
              bvg_value: bvgValue,
          })
          .then(
            async (response) => {
              const { data } = response.data;
              const { name, account } = user_banks[0];

              if (data.id && name) {
                if (account) {
                  await dispatch("addUserBank", {
                    user_id: data.id,
                    name,
                    account,
                  });
                }
              }

              if (file) {
                await dispatch("addUserPhoto", { userId: data.id, file });
              }
              commit("setUsersLoading", true);
              await dispatch("getUsersPage", getters.currentUsersPage);
              resolve(data);
              commit("setUsersLoading", false);
            },
            (err) => {
              commit("setUsersLoading", false);
              reject(err);
            }
          );
      }),

    getUser: (_, userId) =>
      new Promise((resolve, reject) => {
        api({ requiresAuth: true })
          .get(`/v1/users/${userId}`)
          .then(
            (response) => {
              const { data } = response.data;
              resolve(data);
            },
            (error) => {
              reject();
              console.error(error);
            }
          );
      }),

   getProfile: () =>
        new Promise((resolve, reject) => {
            api({ requiresAuth: true })
                .get(`/v1/profile`)
                .then(
                    (response) => {
                        const { data } = response.data;
                        resolve(data);
                    },
                    (error) => {
                        reject();
                        console.error(error);
                    }
                );
        }),

    addUserPhoto: (_, { userId, file }) => {
      const formData = new FormData();
      formData.append("photo", file);
      return api({ requiresAuth: true }).post(
        `/v1/users/${userId}/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },

    updateUser: ({ commit, dispatch }, { user, userId }) =>
      new Promise((resolve, reject) => {
        const {
          companyName,
          firstName,
          lastName,
          email,
          titleId,
          gender,
          password,
          confirmPassword,
          countryCode,
          birthday,
          phone,
          prefix,
          ahvNr,
          roles,
          user_addresses,
          user_banks,
          settings,
          teamId,
          supervisorId,
          salesCategoryId,
          language,
          file,
          hasAhv,
          hasBvg,
          bvgValue
        } = user;
        api({ requiresAuth: true })
          .patch(`/v1/users/${userId}`, {
            company_name: companyName,
            first_name: firstName,
            last_name: lastName,
            email,
            title_id: titleId,
            gender_id: gender,
            country_code: countryCode,
            birthday,
            phone,
            prefix,
            password,
            password_confirmation: confirmPassword,
            ahv_number: ahvNr,
            roles,
            user_addresses,
            user_banks,
            settings,
            team_id: teamId,
            supervisor_id: supervisorId,
            language_id: language,
            sales_category_id: salesCategoryId,
            has_ahv: hasAhv,
            has_bvg: hasBvg,
            bvg_value: bvgValue
          })
          .then(
            async (response) => {
              const { data } = response.data;
              const { id: bankId, name, account } = user_banks[0];

              if (!bankId && (name || account)) {
                await dispatch("addUserBank", {
                  userId: data.id,
                  name,
                  account,
                });
              } else if (bankId) {
                await dispatch("updateUserBank", {
                  bankId,
                  userId: data.id,
                  name,
                  account,
                });
              }

              if (file && router.currentRoute.name !== "AccountDetails") {
                await dispatch("addUserPhoto", { userId: data.id, file });
              }
              if(JSON.parse(localStorage.getItem("user")).id === data.id){
                  localStorage.setItem("needs_reset", false);
              }
              commit("setUsersLoading", true);
              const { page } = router.currentRoute.query;

              if (router.currentRoute.name === "Users") {
                await dispatch("getUsersPage", { page });
              }
              resolve(data);
              commit("setUsersLoading", false);
            },
            (err) => {
              commit("setUsersLoading", false);
              reject(err);
            }
          );
      }),
      updateProfile: ({ commit, dispatch }, { user }) =>
      new Promise((resolve, reject) => {
        const {
          firstName,
          lastName,
          email,
          titleId,
          gender,
          password,
          confirmPassword,
          countryCode,
          birthday,
          phone,
          prefix,
          ahvNr,
          roles,
          user_addresses,
          user_banks,
          settings,
          language,
          file,
        } = user;
        api({ requiresAuth: true })
          .patch(`/v1/profile`, {
            first_name: firstName,
            last_name: lastName,
            email,
            title_id: titleId,
            gender_id: gender,
            country_code: countryCode,
            birthday,
            phone,
            prefix,
            password,
            password_confirmation: confirmPassword,
            ahv_number: ahvNr,
            roles,
            user_addresses,
            user_banks,
            settings,


            language_id: language,

          })
          .then(
            async (response) => {
              const { data } = response.data;
              const { id: bankId, name, account } = user_banks[0];

              if (!bankId && (name || account)) {
                await dispatch("addUserBank", {
                  userId: data.id,
                  name,
                  account,
                });
              } else if (bankId) {
                await dispatch("updateUserBank", {
                  bankId,
                  userId: data.id,
                  name,
                  account,
                });
              }

              if (file && router.currentRoute.name !== "AccountDetails") {
                await dispatch("addUserPhoto", { userId: data.id, file });
              }

              localStorage.setItem("needs_reset", false);

              commit("setUsersLoading", true);
              const { page } = router.currentRoute.query;

              if (router.currentRoute.name === "Users") {
                await dispatch("getUsersPage", { page });
              }
              resolve(data);
              commit("setUsersLoading", false);
            },
            (err) => {
              commit("setUsersLoading", false);
              reject(err);
            }
          );
      }),

    getUsersPage: async ({ commit }, { page = 1, search }) => {
      try {
        let response = "";
        if (search) {
          response = await api({ requiresAuth: true }).get(
            `/v1/search/users?page=${page}&search=${search}`
          );
          if (
            Number(router.history.current.query.page) !==
            response.data.meta.current_page
          ) {
            router.replace({
              query: {
                page: response.data.meta.current_page,
              },
            });
          }
        } else {
          response = await api({ requiresAuth: true }).get(
            `/v1/users?page=${page}`
          );
        }
        const { data } = response;
        commit("setUsersPerPage", data);
      } catch (err) {
        console.error(err);
      }
    },

    getNotifications: async ({ commit }) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          "/v1/user-notifications"
        );
        const { data } = response.data;
        commit("setNotifications", data);
      } catch (err) {
        console.error(err);
      }
    },

    readNotification: async (_, notificationId) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/user-notifications/${notificationId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    changeUserPassword: (_, { userId, password, confirmPassword }) =>
      api({ requiresAuth: true }).patch(`/v1/users/${userId}/passwords`, {
        password,
        password_confirmation: confirmPassword,
      }),

    resetUserPassword: (_, userId) =>
      api({ requiresAuth: true }).post(`/v1/users/${userId}/reset-passwords`),
  },
};
