import api from "@/api";

export default {
  state: {
    selectedPolicyService: {},
  },
  getters: {
    selectedPolicyService: (state) => state.selectedPolicyService,
  },
  mutations: {
    setSelectedPolicyService: (state, data) => {
      state.selectedPolicyService = data;
    },
  },
  actions: {
    deletePolicyService: async (_, serviceId) => {
      try {
        await api({ requiresAuth: true }).delete( 
          `/v1/policy-services/${serviceId}`
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePolicyServiceInterval: async (_, { serviceId, intervalId }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policy-services/${serviceId}/intervals`,
          {
            premium_interval_id: intervalId,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePolicyServiceStartDate: async (_, { serviceId, date }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policy-services/${serviceId}/startDate`,
          {
            start_date: date,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePolicyServiceEndDate: async (_, { serviceId, date }) => {
      try {
        await api({ requiresAuth: true }).patch(
          `/v1/policy-services/${serviceId}/endDate`,
          {
            end_date: date,
          }
        );
      } catch (err) {
        console.error(err);
      }
    },

    updatePolicyServiceBruttoPremium: async (_, { serviceId, bruttoPremium }) => {
      try {
        await api({ requiresAuth: true }).patch(
            `/v1/policy-services/${serviceId}/premium`,
            {
              premium: bruttoPremium,
            }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
};
