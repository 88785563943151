import axios from "axios";
import { baseURL } from "@/config";
import router from "@/router";
import store from "./store";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json"
  }

});
axios.defaults.withCredentials = true;
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { status } = error.response;
    const { message } = error.response.data;
    if (status === 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      if (router.currentRoute.name !== "Login") {
        router.push({ name: "Login" });
      }
      store.commit("setToast", {
        display: true,
        type: "error",
        message,
      });
    } else if (status === 403) {
      router.push({
        name: "Dashboard",
      });
      store.commit("setToast", {
        display: true,
        type: "error",
        message,
      });
    } else if (status === 500) {
      store.commit("setToast", {
        display: true,
        type: "error",
        message: "Something went wrong.",
      });
    }
    return Promise.reject(error);
  }
);

export default ({ requiresAuth = true }) => {
  if (requiresAuth) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  }
  return axiosInstance;
};
