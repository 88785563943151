<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>
      <div
        class="modal__main-icon modal__main-icon--accident"
        v-if="policyFilesModalIsAccident || policyFilesModalIsAccidentChildren"
      ></div>
      <div class="modal__main-icon modal__main-icon--policy" v-else></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="modal__title" v-if="policyFilesModalIsTermination">
        Policy Files (Termination)
      </div>
      <div class="modal__title" v-else-if="policyFilesModalIsPotential">
        Potential Policy Files
      </div>
      <div
        class="modal__title"
        v-else-if="
          policyFilesModalIsAccident || policyFilesModalIsAccidentChildren
        "
      >
        Files
      </div>
      <div class="modal__title" v-else>Policy Files</div>

      <div class="content">
        <div class="files">
          <div class="files__header">
            <div class="files__label files__label--large">Name</div>
            <div class="files__label files__label--small">View</div>
            <div class="files__label files__label--medium">Date</div>
            <div
              class="files__label files__label--small"
              v-if="!policyFilesModalIsTermination"
            >
              App
            </div>
            <!-- <div class="files__label files__label--small">Private</div> -->
            <div class="files__label files__label--small">Delete</div>
          </div>
          <div class="files__body">
            <Loading v-if="filesLoading" />
            <template v-else>
              <File
                v-for="file in policyFiles"
                :key="file.id"
                :file="file"
                :updateFiles="updateFiles"
              />
            </template>
          </div>
        </div>
        <DropFile @input="uploadFiles($event)" :deletable="false" :bus="bus" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import File from "@/components/AccountDetails/File";
import DropFile from "@/components/DropFile";
import Vue from "vue";
import Loading from "@/partials/Loading";
export default {
  name: "PolicyFilesModal",

  components: {
    File,
    DropFile,
    Loading,
  },

  data() {
    return {
      files: [],
      bus: new Vue(),
      filesLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "policyFiles",
      "policyFileId",
      "customer",
      "policyFilesModalIsTermination",
      "policyFilesModalIsPotential",
      "policyFilesModalIsAccident",
      "policyFilesModalIsAccidentChildren",
    ]),
  },

  destroyed() {
    this.$store.commit("setPolicyFilesModalIsTermination", false);
    this.$store.commit("setPolicyFilesModalIsPotential", false);
    this.$store.commit("setPolicyFilesModalIsAccident", false);
    this.$store.commit("setPolicyFilesModalIsAccidentChildren", false);
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowPolicyFilesModal", false);
    },

    async uploadFiles(event) {
      this.filesLoading = true;
      this.files = event;

      const files = this.files.map((file) => file.file);

      if (this.policyFilesModalIsTermination) {
        await this.$store.dispatch("uploadTerminationFiles", {
          terminationId: this.policyFileId,
          files,
        });
      } else if (this.policyFilesModalIsPotential) {
        await this.$store.dispatch("addPotentialPolicyFile", {
          potentialPolicyId: this.policyFileId,
          files,
        });
      } else if (this.policyFilesModalIsAccident) {
        await this.$store.dispatch("addAccidentFile", {
          accidentId: this.policyFileId,
          files,
        });
      } else if (this.policyFilesModalIsAccidentChildren) {
        await this.$store.dispatch("addAccidentFile", {
          accidentId: this.policyFileId,
          files,
        });
      } else {
        await this.$store.dispatch("addPolicyFile", {
          policyId: this.policyFileId,
          files,
        });
      }
      await this.updateFiles();
      this.bus.$emit("delete");
      this.filesLoading = false;
    },

    async updateFiles() {
      const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);

      if (this.policyFilesModalIsTermination) {
        this.$store.commit("setPolicyFiles", [
          ...this.customer.terminations.find(
            (termination) => termination.id === this.policyFileId
          ).termination_files,
        ]);
      } else if (this.policyFilesModalIsPotential) {
        this.$store.commit("setPolicyFiles", [
          ...this.customer.potential_policies.find(
            (policy) => policy.id === this.policyFileId
          ).files,
        ]);
      } else if (this.policyFilesModalIsAccident) {
        this.$store.commit("setPolicyFiles", [
          ...this.customer.policies
            .map((policy) => policy.accidents)
            .flat()
            .find((accident) => accident.id === this.policyFileId)
            .accident_files,
          ...this.customer.policies
            .map((policy) => policy.accidents)
            .flat()
            .find((accident) => accident.id === this.policyFileId)
            .childrens.map((accident) => accident.accident_files)
            .flat(),
        ]);
      } else if (this.policyFilesModalIsAccidentChildren) {
        this.$store.commit("setPolicyFiles", [
          ...this.customer.policies
            .map((policy) => policy.accidents)
            .flat()
            .map((accident) => accident.childrens)
            .flat()
            .find((accident) => accident.id === this.policyFileId)
            .accident_files,
        ]);
      } else {
        this.$store.commit("setPolicyFiles", [
          ...this.customer.policies.find(
            (policy) => policy.id === this.policyFileId
          ).policy_files,
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__title {
    margin: 53px 0 35px 10px;
    font-size: var(--medium);
    font-family: var(--muli-text-semi-bold);
  }

  .modal__title,
  .files__label,
  ::v-deep.file {
    color: var(--text-2);
  }

  .content {
    display: flex;
    align-items: flex-start;
    height: calc(100% - 53px - 35px - 18px);
  }

  .files {
    width: 100%;
    height: 100%;
    margin-right: 20px;
    font-size: var(--x-small);

    &__header {
      border-bottom: 1px solid var(--border-10);
      display: flex;
      padding: 5px 10px;
      margin-bottom: 8px;
    }

    &__body {
      height: calc(100% - 25px - 8px);
      overflow: auto;
      position: relative;
    }

    ::v-deep.file {
      display: flex;
      padding: 20px 10px;
      background: var(--bg-3);
      margin-bottom: 1px;
      position: relative;
      height: 64px;
      max-height: 64px;
      transition: all 0.3s ease;

      &__data {
        &--large {
          color: var(--text-10);
          text-decoration: underline;
        }
      }

      .icon {
        background-position: left;
      }
    }

    &__label,
    ::v-deep.file__data {
      display: flex;
      align-items: center;
      &--large {
        flex: 3;
      }
      &--medium {
        flex: 2;
      }
      &--small {
        flex: 1;
      }
    }
  }
}
</style>
