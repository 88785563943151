<template>
  <div class="contacts">
    <div class="page__header">
      <div class="page__title">Contact</div>

      <div class="contacts__buttons">
        <button
          type="button"
          class="button button--default button--shadow"
          @click="$store.commit('setShowAddCompanyContactModal', true)"
        >
          Add New
        </button>
      </div>
    </div>

    <div class="contacts__content">
      <div
        class="contacts__list box box--default"
        :class="{ 'contacts__list--hide': showModels }"
      >
        <div class="label label--gradient">Companies</div>
        <Loading v-if="companiesLoading" />
        <template v-else>
          <div class="companies">
            <div class="companies__search">
              <form class="search" @submit.prevent>
                <input
                  type="text"
                  class="search__search"
                  :class="{ 'search--active': companySearchKeywords }"
                  v-model="companySearchKeywords"
                />
              </form>
            </div>

            <div class="companies__list">
              <Contact
                v-for="company in filteredCompanies"
                :key="company.id"
                :company="company"
                :selectedCompany="selectedCompany"
                :selectCompany="selectCompany"
              />
            </div>
          </div>
        </template>
      </div>

      <div
        class="contacts__overview box box--default"
        :class="{ 'contacts__overview--toggled': showModels }"
      >
        <div class="label label--gradient">Contact</div>
        <Loading v-if="companiesLoading" />
        <template v-else>
          <div class="contact">
            <div class="company">
              <div class="company__header">
                <div class="company__logo">
                  <img
                    v-if="selectedCompany.logo"
                    :src="selectedCompany.logo.url"
                    alt=""
                  />
                </div>
                <span>{{ selectedCompany.name }}</span>
                <div
                  class="company__edit icon icon__edit"
                  @click="editContact()"
                ></div>
              </div>

              <div class="company__contacts">
                <div class="company__contact">
                  <div class="company__icon company__icon--location"></div>
                  <div class="company__detail">
                    {{ selectedCompany.address }}
                  </div>
                  <div class="company__detail">{{ selectedCompany.city }}</div>
                  <div class="company__detail">{{ selectedCompany.zip }}</div>
                </div>

                <div class="company__contact">
                  <div class="company__icon company__icon--mobile"></div>
                  <div class="company__detail">{{ selectedCompany.phone }}</div>
                </div>

                <div class="company__contact">
                  <div class="company__icon company__icon--mail"></div>
                  <div class="company__detail">{{ selectedCompany.email }}</div>
                </div>

                <div class="company__contact">
                  <div class="company__icon company__icon--web"></div>
                  <div class="company__detail">{{ selectedCompany.web }}</div>
                </div>
              </div>
            </div>

            <div class="company__contact comment" v-if="selectedCompany.comment"> 
                  <div class="company__detail">{{ selectedCompany.comment }}</div>
                </div>

            <div class="contact__map-wrapper">
              <div
                class="contact__services"
                :class="{
                  'contact__services--center': !selectedCompany.services.length,
                }"
              >
                <div class="services">
                  <div
                    class="service"
                    v-for="service in selectedCompany.services"
                    :key="service.id"
                    @click="selectService(service)"
                  >
                    {{ service[`long_${language}`] }}
                  </div>
                  <DropdownServices
                    class="service--add"
                    :dropdownServices="branchCategoryServices"
                    :services="selectedCompany.services"
                    :applyServices="applyServices"
                    :addOrRemoveServices="addOrRemoveServices"
                    :closeServicesDropdown="closeServicesDropdown"
                  />
                </div>
              </div>
              <div class="contact__map">
                <Map
                  :lan="selectedCompany.lan"
                  :lat="selectedCompany.lat"
                  :address="companyAddress"
                />
              </div>
            </div>
          </div>
        </template>
      </div>

      <transition name="fade">
        <div
          class="contacts__models box box--default"
          :class="{ 'contacts__models--show': showModels }"
          v-if="showModels"
        >
          <div class="label label--gradient">
            <span>
              {{ selectedService.name }}
              <div class="label__close" @click="showModels = false"></div>
            </span>
          </div>

          <Loading v-if="modelsLoading" />
          <template v-else>
            <div class="models">
              <NoData v-if="!models.length" :type="'models'" />
              <div class="models__list">
                <template v-if="models.length">
                  <Model
                    v-for="model in models"
                    :key="model.id"
                    :model="model"
                    :getModels="getModels"
                  />
                </template>
              </div>
              <button
                class="button button--default button--shadow"
                v-if="models.length"
                @click="$store.commit('setShowAddModelModal', true)"
              >
                Add New Model
              </button>
            </div>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Contact from "@/components/Contacts/Contact";
import Loading from "@/partials/Loading";
import Map from "@/components/Contacts/Map";
import Model from "@/components/Contacts/Model";
import NoData from "@/partials/NoData";
import DropdownServices from "@/partials/DropdownServices";
export default {
  name: "Contacts",

  components: {
    Contact,
    Loading,
    Map,
    Model,
    NoData,
    DropdownServices,
  },

  data() {
    return {
      companiesLoading: false,
      companySearchKeywords: "",
      showModels: false,
      selectedService: {
        name: "",
        id: "",
      },
      modelsLoading: false,
      preServices: [],
    };
  },

  watch: {
    async "selectedService.id"(serviceId) {
      this.modelsLoading = true;
      await this.getModels(serviceId);
      this.modelsLoading = false;
    },

    async "selectedCompany.id"(companyId) {
      this.modelsLoading = true;
      await this.getModels(this.selectedService.id, companyId);
      this.modelsLoading = false;
    },
  },

  computed: {
    ...mapGetters([
      "showAddServiceModal",
      "companies",
      "models",
      "branchCategoryServices",
      "selectedCompany",
      "language",
    ]),

    filteredCompanies() {
      return this.companies.filter((company) =>
        company.name
          .toLowerCase()
          .trim()
          .includes(this.companySearchKeywords.toLowerCase().trim())
      );
    },

    companyAddress() {
      return `${this.selectedCompany.city} ${this.selectedCompany.zip} ${this.selectedCompany.address}`;
    },
  },

  async created() {
    this.companiesLoading = true;
    await this.$store.dispatch("getBranchCategoryServices");
    await this.$store.dispatch("getCompanies");
    const [firstCompany] = this.companies;
    this.$store.commit("setSelectedCompany", firstCompany);
    const [firstService] = firstCompany.services;
    this.selectedService.id = firstService.id;
    this.selectedService.name = firstService[`long_${this.language}`];
    this.$store.commit("setSelectedService", firstService);
    this.preServices = [...this.selectedCompany.services];
    this.companiesLoading = false;
  },

  methods: {
    selectCompany(company) {
      this.$store.commit("setSelectedCompany", company);
    },

    selectService(service) {
      this.selectedService.id = service.id;
      this.selectedService.name = service[`long_${this.language}`];
      this.$store.commit("setSelectedService", service);
      this.showModels = true;
    },

    addOrRemoveServices(event, service) {
      const { checked } = event.target;
      const existingService = this.preServices.findIndex(
        (s) => s.id === service.id
      );
      if (checked) {
        if (existingService === -1) {
          this.preServices = [...this.preServices, service];
        }
      } else {
        this.preServices.splice(existingService, 1);
      }
    },

    async applyServices() {
      const services = this.preServices.map((service) => service.id);
      try {
        await this.$store.dispatch("updateCompany", {
          companyId: this.selectedCompany.id,
          services,
        });
        const company = await this.$store.dispatch(
          "getCompany",
          this.selectedCompany.id
        );

        const indexOfCompanyToReplace = this.companies.findIndex(
          (c) => c.id === company.id
        );
        const tempArray = [...this.companies];
        tempArray[indexOfCompanyToReplace] = company;
        this.$store.commit("setSelectedCompany", company);
        this.$store.commit("setCompanies", tempArray);
      } catch (err) {
        console.error(err);
      }
    },

    closeServicesDropdown() {
      this.preServices = [...this.selectedCompany.services];
    },

    async getModels(
      serviceId = this.selectedService.id,
      companyId = this.selectedCompany.id
    ) {
      try {
        const models = await this.$store.dispatch(
          "getModelsByCompanyAndServices",
          {
            companyId,
            serviceId,
          }
        );
        this.$store.commit("setModels", models);
      } catch (err) {
        console.error(err);
      }
    },

    editContact() {
      this.$store.commit("setContactToEdit", this.selectedCompany);
      this.$store.commit("setShowAddCompanyContactModal", true);
    },
  },
};
</script>

<style scoped lang="scss">
.contacts {
  height: 100%;
  &__content {
    display: flex;
    padding-top: 30px;
    height: calc(100% - 48px - 20px - 10px);
    position: relative;
    overflow-x: hidden;
  }

  &__buttons {
    display: flex;

    .button {
      width: 190px;

      &:first-of-type {
        margin-right: 50px;
      }
    }
  }

  &__list,
  &__overview,
  &__models {
    background: var(--bg-1);
    position: relative;
    height: 100%;
    z-index: 2;
    transition: all 0.3s ease;
  }

  &__list,
  &__models {
    width: 500px;
    position: absolute;
    height: calc(100% - 30px);
  }

  &__models {
    z-index: 0;
    right: 0;
    padding-top: 50px;

    &--show {
      margin-left: 60px;
      z-index: 4;
    }

    span {
      position: relative;

      .label__close {
        position: absolute;
        top: -25px;
        right: -35px;
        width: 20px;
        height: 20px;
        background: url("../assets/icons/default/x_delete_icon.svg") no-repeat
          center;
        background-size: 100%;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          transform: rotate(180deg);
        }
      }
    }

    .models {
      height: 100%;

      &__list {
        overflow: auto;
        margin-bottom: 15px;
        max-height: calc(100% - 48px - 15px - 15px);
      }

      .button {
        margin: auto;
      }
    }
  }

  &__list {
    margin-right: 60px;
    transform: translateX(0);
    opacity: 1;

    &--hide {
      transform: translateX(-100%);
      opacity: 0;
      margin-right: 0;
    }

    .companies {
      padding: 60px 10px 10px 10px;
      height: 100%;

      &__search {
        margin-bottom: 20px;
        .search {
          &__search {
            padding-left: 45px;
            width: 100%;
            background-position: 10px center;
          }
        }
      }

      &__list {
        padding-top: 10px;
        max-height: calc(100% - 40px - 20px);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &__overview {
    width: calc(100% - 500px - 60px);
    margin-left: 560px;

    &--toggled {
      margin-left: 0;
    }

    .contact {
      padding-top: 80px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .company {
        flex: 1;
        &__header {
          background: var(--bg-17);
          padding: 12px 0;
          color: var(--text-9);
          font-size: var(--medium);
          text-align: center;
          position: relative;
        }

        &__logo {
          position: absolute;
          height: 81px;
          width: 165px;
          background: var(--bg-1);
          border-radius: var(--radius-1);
          box-shadow: 0px 3px 7px var(--shadow-1);
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 54px;
          }
        }

        &__edit {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

        &__contacts {
          margin: 60px 0 0 80px;
          display: flex;
        }

        &__contact {
          flex: 1;
        }

        &__detail {
          margin-bottom: 8px;
          color: var(--text-9);
        }

        &__icon {
          height: 24px;
          width: 24px;
          margin-bottom: 20px;

          &--location {
            background: url("../assets/icons/default/location_icon.svg");
          }
          &--mobile {
            background: url("../assets/icons/default/mobile_icon.svg");
          }
          &--mail {
            background: url("../assets/icons/default/mail_icon.svg");
          }
          &--web {
            background: url("../assets/icons/default/web_icon.svg");
          }
        }
      }

      &__services {
        padding: 5px 15px;

        &--center {
          .services {
            justify-content: center;
          }
        }

        .services {
          overflow: auto;
          max-height: 184px;
          padding: 20px 10px 2px 10px;
          background: var(--bg-17);
          border-radius: var(--radius-1);
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .service {
            background: var(--bg-2);
            cursor: pointer;
            padding: 10px 5px;
            color: var(--text-1);
            margin: 0 18px 18px 0;
            border-radius: var(--radius-1);
            box-shadow: 0px 7px 18px var(--shadow-10);
            transition: all 0.3s ease;

            &:hover:not(.service--add) {
              background: var(--hover-1);
              box-shadow: 0px 0px 20px var(--shadow-10);
            }

            &--add {
              box-shadow: none;
              margin: 0 18px 18px 0;
            }
          }
        }
      }

      &__map-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &__map {
        padding: 0 15px 15px 15px;
        width: 100%;
        height: 100%;
        flex: 1;
      }
    }
  }

  .comment{
    margin: 20px 0;
    padding: 0 20px;
  }
}
</style>
