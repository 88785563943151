<template>
  <div
    class="search-filter"
    ref="searchFilter"
    v-clickOutside="toggleSearchFilter"
  >
    <div class="search-filter__content">
      <form class="search-filter__form" @submit.prevent="search()">
        <div class="search-filter__title">Apply Filter</div>
        <div class="search-filter__row">
          <div class="search-filter__column">
            <MultiSelectDropdown
              :items="resource.companyTypes"
              placeholder="Type"
              label="type"
              @input="companyTypes = $event"
              :reduce="(type) => type.id"
              :selected="companyTypes"
            />
          </div>
          <div class="search-filter__column" v-if="$route.name === 'Customers'">
            <MultiSelectDropdown
              :items="resource.companies"
              placeholder="Company"
              label="name"
              @input="companies = $event"
              :reduce="(company) => company.id"
              :selected="companies"
              v-if="$route.name === 'Customers'"
            />
          </div>
          <div class="search-filter__column">
            <MultiSelectDropdown
              :items="resource.branchCategories"
              placeholder="Category"
              :label="`long_${language}`"
              @input="branchCategories = $event"
              :reduce="(category) => category.id"
              :selected="branchCategories"
            />
          </div>
        </div>
        <div class="search-filter__row">
          <div class="search-filter__column" v-if="$route.name === 'Customers'">
            <MultiSelectDropdown
              :items="resource.policyStatuses"
              placeholder="Status"
              label="name"
              @input="policyStatuses = $event"
              :reduce="(status) => status.id"
              :selected="policyStatuses"
              v-if="$route.name === 'Customers'"
            />
          </div>
          <div class="search-filter__column">
            <MultiSelectDropdown
              :items="resource.agents"
              placeholder="Agent"
              @input="agents = $event"
              :customLabel="(agent) => `${agent.first_name} ${agent.last_name}`"
              :reduce="(agent) => agent.id"
              :selected="agents"
            />
          </div>
          <div
            class="search-filter__column"
            v-if="$route.name === 'PotentialCustomers'"
          >
            <MultiSelectDropdown
              :items="resource.priorities"
              placeholder="Priorities"
              @input="priorities = $event"
              label="type"
              :reduce="(priority) => priority.id"
              :selected="priorities"
            />
          </div>
          <div class="search-filter__column search-filter__column--inputs">
            <div class="search-filter__column">
              <Datepicker
                class="input input--default"
                :config="statics.datePickerConfig"
                placeholder="Birthday"
                v-model="birthday"
              />
            </div>
            <div class="search-filter__column">
              <input
                type="text"
                class="input input--default"
                placeholder="Zip"
                v-model="zip"
              />
            </div>
          </div>
        </div>
        <div class="search-filter__row">
          <div class="search-filter__column search-filter__column--inputs">
            <div class="search-filter__column">
              <Datepicker
                class="input input--default"
                :config="statics.datePickerConfig"
                placeholder="Start Date"
                v-model="startDate"
              />
            </div>
            <div class="search-filter__column">
              <Datepicker
                class="input input--default"
                :config="statics.datePickerConfig"
                placeholder="End Date"
                v-model="endDate"
              />
            </div>
            <div class="search-filter__column" v-if="$checkRolePermission('customer_export')">
              <a
                  v-show="!isRequesting"
                  type="button"
                  @click="xlsExport()"
                  class="search-export__button button button--default"
              >

                Export
                <img src="../assets/icons/default/export_files_icon.svg" alt="" />
              </a>
            </div>
          </div>
          <div class="search-filter__column"></div>
          <div class="search-filter__column"></div>
          <div class="search-filter__column">     </div>
        </div>

        <div
          v-show="isRequesting"
          ref="loadingButton"
          class="search-filter__button button button--default button--loading"
        ></div>
        <button
          v-show="!isRequesting"
          type="button"
          @click="search()"
          class="search-filter__button button button--default"
        >
          Apply Filter
        </button>

      </form>
    </div>
  </div>
</template>

<script>
import statics from "@/statics";
import { mapGetters } from "vuex";
import withPopper from "@/helpers/withPopper";
import MultiSelectDropdown from "@/partials/MultiSelectDropdown";
export default {
  name: "SearchFilter",

  components: { MultiSelectDropdown },

  props: {
    toggleSearchFilter: {
      required: true,
      type: Function,
    },
    filterToggler: {
      required: true,
      type: null,
    },
  },

  directives: {
    clickOutside: {
      bind: (el, binding, vnode) => {
        el.clickEvent = (event) => {
          if (
            event.target === vnode.context.filterToggler ||
            event.target === vnode.context.$refs.searchFilter ||
            [...el.getElementsByTagName("*")].includes(event.target)
          ) {
            vnode.context[binding.expression](true);
          } else {
            vnode.context[binding.expression](false);
          }
        };
        document.addEventListener("click", el.clickEvent);
      },
      unbind: (el) => {
        document.removeEventListener("click", el.clickEvent);
      },
    },
  },

  data() {
    return {
      statics,
      withPopper,
      isRequesting: false,
      birthday: "",
      zip: "",
      startDate: "",
      endDate: "",
      agents: [],
      companyTypes: [],
      companies: [],
      branchCategories: [],
      policyStatuses: [],
      priorities: [],
      searchKeywords: "",
      resource: {},
      export: false,
    };
  },
  async created() {
    if (this.$route.name === "PotentialCustomers") {
      this.resource = this.searchPotentialCustomerForms;
    } else {
      this.resource = this.searchCustomerForms;
    }
    const {
      search,
      agents,
      companyTypes,
      companies,
      branchCategories,
      policyStatuses,
      priorities,
      birthday,
      startDate,
      endDate,
      zip,
    } = this.$route.query;
    this.searchKeywords = search || "";
    this.agents = agents ? agents.split(",").map((i) => i) : [];
    this.companyTypes = companyTypes
      ? companyTypes.split(",").map((i) => i)
      : [];
    this.companies =
      companies && this.$route.name === "Customers"
        ? companies.split(",").map((i) => i)
        : [];
    this.branchCategories = branchCategories
      ? branchCategories.split(",").map((i) => i)
      : [];
    this.policyStatuses =
      policyStatuses && this.$route.name === "Customers"
        ? policyStatuses.split(",").map((i) => i)
        : [];
    this.priorities =
      priorities && this.$route.name === "PotentialCustomers"
        ? priorities.split(",").map((i) => i)
        : [];
    this.birthday = birthday || "";
    this.startDate = startDate || "";
    this.endDate = endDate || "";
    this.zip = zip || "";
  },

  computed: {
    ...mapGetters([
      "searchCustomerForms",
      "searchPotentialCustomerForms",
      "language",
    ]),
  },

  methods: {
    async search() {
      this.isRequesting = true;

      if (this.$route.name === "Customers") {
        await this.$store.dispatch("searchCustomers", {
          search: this.searchKeywords,
          agents: this.agents,
          companyTypes: this.companyTypes,
          companies: this.companies,
          branchCategories: this.branchCategories,
          policyStatuses: this.policyStatuses,
          birthday: this.birthday,
          startDate: this.startDate,
          endDate: this.endDate,
          zip: this.zip,
        });
        this.$router.replace({
          query: {
            search: this.searchKeywords,
            agents: this.agents.join(","),
            companyTypes: this.companyTypes.join(","),
            companies: this.companies.join(","),
            branchCategories: this.branchCategories.join(","),
            policyStatuses: this.policyStatuses.join(","),
            birthday: this.birthday,
            startDate: this.startDate,
            endDate: this.endDate,
            zip: this.zip,
          },
        });
      } else if (this.$route.name === "PotentialCustomers") {
        await this.$store.dispatch("searchPotentialCustomers", {
          search: this.searchKeywords,
          agents: this.agents,
          companyTypes: this.companyTypes,
          branchCategories: this.branchCategories,
          priorities: this.priorities,
          birthday: this.birthday,
          startDate: this.startDate,
          endDate: this.endDate,
          zip: this.zip,
        });
        this.$router.replace({
          query: {
            search: this.searchKeywords,
            agents: this.agents.join(","),
            companyTypes: this.companyTypes.join(","),
            branchCategories: this.branchCategories.join(","),
            priorities: this.priorities.join(","),
            birthday: this.birthday,
            startDate: this.startDate,
            endDate: this.endDate,
            zip: this.zip,
          },
        });
      }
      this.isRequesting = false;
    },
    async xlsExport() {
      this.isRequesting = true;
      if (this.$route.name === "Customers") {
        await this.$store.dispatch("searchCustomers", {
          search: this.searchKeywords,
          agents: this.agents,
          companyTypes: this.companyTypes,
          companies: this.companies,
          branchCategories: this.branchCategories,
          policyStatuses: this.policyStatuses,
          birthday: this.birthday,
          startDate: this.startDate,
          endDate: this.endDate,
          zip: this.zip,
          export: true
        });
      }
      this.isRequesting = false;

    }
  },
};
</script>

<style scoped lang="scss">
.search-filter {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--bg-1);
  width: 900px;
  padding: 60px;
  z-index: 2;
  box-shadow: 0px 5px 30px var(--shadow-4);
  border-radius: var(--radius-3);
  .search-export__button {
    background: #B9C2CD;
    box-shadow: none;
    height: 40px;
    width: 115px !important;
    padding-right: 10px;
    margin-left: 0px !important;
    padding-left: 10px !important;
    text-align: left;
    position: relative;
    line-height: 38px;
    font-size: 16px;
    cursor: pointer;
    &:hover{
      background: var(--text-5);
    }
    img {
     position: absolute;
      right: 5px;
      width: 27px;
      bottom: 7px;
    }
  }
  &__title {
    color: var(--text-5);
    font-family: var(--muli-text-semi-bold);
    font-size: var(--medium);
    margin-bottom: 50px;
  }

  &__row,
  &__column--inputs {
    display: flex;
  }

  &__row {
    margin-bottom: 40px;
  }

  &__column {
    flex: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    margin: 60px auto 0 auto !important;
  }
}
</style>
