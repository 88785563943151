<template>
  <div class="notification"  :class="{__read: isRead}" @click.self="notificationClicked">
    <div
        v-if="!isRead"
      class="notification__close"
      @click="readNotification(notification.id)"

    ></div>
    <div class="notification__type"
         :class="'notification__type--'+type()"
    ></div>
    <div class="user">
      <div class="user__avatar"></div>
      <div class="user__name">

      </div>
    </div>
    <div class="notification__text">
      {{ notification.data.message }}
    </div>
    <div class="notification__date">{{ date }}</div>
  </div>
</template>

<script>
import moment from "moment";
import statics from "@/statics";

export default {
  name: "Notification",

  computed :{
    date() {
      return this.notification.created_at
          ? moment(this.notification.created_at).format("DD.MM.YYYY hh:mm")
          : statics.empty;
    },
    isRead() {
      return (this.notification.read_at != null)
    },


    },
  methods: {
    type : function () {
      if(this.notification.type == "App\\Notifications\\CustomerBirthday"){
        return 'birthday'
      }else {
        return  'customer'
      }
    },

    async readNotification() {
      await this.$store.dispatch("readNotification", this.notification.id);
      await this.$store.dispatch("getNotifications");
    },
    notificationClicked ()
    {
      if(!this.isRead)
        this.readNotification();
      if(this.type() == "birthday"){
        this.$router.push({
          name: "AccountDetails",
          params: {
            entity: "customer",
            id: this.notification.data.customer.id,
          },
        });
      }
      this.toggleNotification(false);
    }
  },
  props: {
    notification: {
      required: true,
      type: Object,
    },

    toggleNotification: {
      required: true,
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  padding: 50px 0px 37px 20px;
  background: var(--bg-16);
  border-radius: var(--radius-1);
  position: relative;
  margin-bottom: 30px;
  &.__read{
    background: #fff;
    border: 1px solid #F2F4F6;
  }
  &:last-child {
    margin-bottom: 0;

  }
  &:hover{
    box-shadow: 0px 10px 15px #0000001A;
    cursor: pointer;
  }
  .user {
    top: -27px;
    left: 50%;
    transform: translateX(-50%);

    &__avatar {
      height: 46px;
      width: 46px;
      background: black;
      margin: 3px auto;
      border: 2px solid var(--border-16);
    }

  }

  &__close {
    top: -8px;
    right: -8px;
    height: 24px;
    width: 24px;
    background: url("../assets/icons/default/close_hover_icon.svg");
    cursor: pointer;
  }

  &__type {
    height: 34px;
    width: 34px;
    background-color: var(--bg-1);
    box-shadow: 0px 8px 10px var(--shadow-6);
    left: 15px;
    top: -17px;

    &--customer {
      background-image: url("../assets/icons/default/users_icon.svg");
    }

    &--contact {
      background-image: url("../assets/icons/default/contact_icon_left_menu.svg");
    }

    &--potential {
      background-image: url("../assets/icons/default/potentialcustomer_icon.svg");
    }

    &--user {
      background-image: url("../assets/icons/default/customers_icon.svg");
    }
    &--birthday {
      background-image: url("../assets/icons/default/cake_icon.svg");
    }

    &--customer,
    &--contact,
    &--potential,
    &--user,
    &--birthday{
      background-repeat: no-repeat;
      background-position: center;
      background-size: 24px;
    }

  }

  &__text {
    color: var(--text-9);
    line-height: 18px;
  }

  &__date {
    right: 13px;
    bottom: 6px;
    font-size: var(--x-smaller);
  }

  &__type,
  .user__avatar {
    border-radius: 50%
  }

  .user__avatar {
    background-image: url("../assets/icons/default/notification_icon_new.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #22DB8E;
  }
  &__date,
  .user__name {
    color: var(--text-2);
    font-family: var(--muli-text-semi-bold);
  }

  .user__name,
  &__text {
    font-size: var(--x-small);
  }

  .user,
  &__date,
  &__close,
  &__type {
    position: absolute;
  }
}
</style>
